
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { notification } from "ant-design-vue";
import { useStore } from "vuex";
import { IState, IWebUrl } from "@/interface";
import { getBindCode, setUrl } from "@/service/common";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import QRCode from "qrcode";
import { RuleObject } from "ant-design-vue/lib/form";

export default defineComponent({
  setup() {
    const formEle = ref();
    const formState = reactive<IWebUrl>({
      remoteUrl: "",
      remoteUrlType: 10,
      localUrl: "",
    });
    let validateUrl = async (_rule: RuleObject, value: string) => {
      if (!value) {
        return Promise.reject("请输入地址链接");
      } else if (!value.includes("http://") && !value.includes("https://")) {
        return Promise.reject("地址必须以http://或者https://开头");
      } else if (value.charAt(value.length - 1) === "/") {
        return Promise.reject("地址结尾不需要加/斜杠");
      } else {
        return Promise.resolve();
      }
    };
    const rules = {
      remoteUrl: [
        {
          required: true,
          validator: validateUrl,
        },
      ],
      remoteUrlType: [
        {
          required: true,
        },
      ],
      localUrl: [
        {
          required: true,
          validator: validateUrl,
        },
      ],
    };
    const submitLoading = ref(false);
    const visible = ref(false);
    const showDrawer = () => {
      visible.value = true;
    };

    const closeDrawer = () => {
      formEle.value.resetFields();
      visible.value = false;
    };

    const store = useStore<IState>();
    const remoteUrl = computed(() => store.state.userInfo?.remoteUrl);
    const localUrl = computed(() => store.state.userInfo?.localUrl);
    const isIPv6 = computed(() => store.state.userInfo?.remoteUrlType === 20);
    const sumbitHandle = () => {
      console.log(formState);
      formEle.value.validate().then(() => {
        submitLoading.value = true;
        setUrl(formState.remoteUrl, formState.remoteUrlType, formState.localUrl)
          .then((res) => {
            if (res.code === 200) {
              notification.success({
                message: "成功",
              });
              closeDrawer();
              getUserInfo();
              rushBindCode();
            }
          })
          .finally(() => {
            submitLoading.value = false;
          });
      });
    };
    const testOpenLink = (url?: string) => {
      if (!url) {
        notification.error({
          message: "出错了，请刷新页面后重试",
        });
        return;
      }
      window.open(url);
    };
    const setOpenLink = () => {
      showDrawer();
    };
    const getUserInfo = () => {
      store.dispatch("GetUserInfo");
    };
    getUserInfo();
    const bindCodeUrl = ref<string>();
    const rushBindCode = async () => {
      getBindCode().then((res) => {
        if (res.code === 200) {
          const bindCode = res.data;
          QRCode.toDataURL(bindCode)
            .then((url: string) => {
              bindCodeUrl.value = url;
              console.log(bindCodeUrl.value);
            })
            .catch(() => {
              notification.error({
                message: "生成绑定二维码失败，请刷新页面重试！",
              });
            });
        }
      });
    };
    rushBindCode();
    watch(remoteUrl, (val) => {
      if (val) {
        rushBindCode();
      } else {
        bindCodeUrl.value = "";
      }
    });
    return {
      formState,
      submitLoading,
      sumbitHandle,
      rules,
      formEle,
      remoteUrl,
      localUrl,
      testOpenLink,
      setOpenLink,
      closeDrawer,
      visible,
      isIPv6,
      bindCodeUrl,
    };
  },
});
